.passwordInput {
  width: 37.815vw;
  height: 6.4vh;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  color: #113a62;
  background: #e3edf7;
  border-radius: 4px;
  margin-top: 1vh;
  margin-bottom: 3vh;
  padding-left: 1vw;

  margin-bottom: 4vh;
}
.formLabel {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #8b9eb0;
}
.inputField {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  width: 37.815vw;
  height: 6vh;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  color: #113a62;
  background-color: #e3edf7;
  box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6),
    inset 4px 4px 14px #c5d7ee;
}
.toggleButton {
  background-color: transparent;
  outline: none;
  border: none;
}
.eyeIcon {
  width: 1.5vw;
  height: 1.5vh;
  margin-left: 1vw;
}
