.heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 2.2vw;
  line-height: 20px;
  /* identical to box height, or 77% */

  letter-spacing: -0.4px;

  color: #000000;
  margin-bottom: 4.7vh;
}
.dashboard {
  margin-left: 8.208vw;

  margin-top: 8.273vh;
}
/* .dashboardGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 5.36vw;
  gap: 2.203vh;
  margin-bottom: 6vh;
}
.gridContent {
  display: flex;
  padding: 18px 22px;
  border: 1px solid #d6e3f3;
  border-radius: 5px;
}
#gridCount {
  font-size: 4vh;
  font-weight: bold;
}
.gridInfo {
  display: flex;
  flex-direction: row;
  margin-left: 1.675vw;
} */

.dashboardGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 5.36vw;
  gap: 2.203vh;
  margin-bottom: 6vh;
  /* gap: 1rem; */
  /* background-color: aqua; */
}

.gridContent {
  display: flex;
  padding: 18px 22px;
  border: 1px solid #d6e3f3;
  border-radius: 5px;
}

.gridImgContainer {
  /* background-color: aqua; */
  display: flex;
  align-items: center;
}

#gridCount {
  color: #000;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 32px */
  letter-spacing: -0.4px;
}

.gridInfo {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  /* background-color: lightcoral; */
}

.personalInfo {
  color: #8b9eb0;
  font-weight: 700;
  margin-bottom: 2.203vh;
}
.links {
  margin-right: 2.01vw;
  text-decoration: none;
  color: #1d59af;
  font-weight: 600;
}
.infoContainer {
  padding-bottom: 6vh;
}
.btnGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 66vw;
}
.editBlock {
  display: flex;
  justify-content: space-between;
  width: 66vw;
}
.edit {
  width: 14vw;
  color: #fff;
  padding: 18px 22px;
  border: none;
  background-color: #366cbd;
  box-shadow: 2px 2px 4px rgba(114, 142, 171, 0.1), -6px -6px 20px #ffffff,
    4px 4px 20px rgba(111, 140, 176, 0.41);
  border-radius: 4px;
  margin-bottom: 4vh;
}
