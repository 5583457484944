.mainBlock {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sulzerLogo {
  width: 20vw;
  height: 5vh;
  object-fit: contain;
  margin-top: 5vh;
  margin-left: 4vw;
}
.middleBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px;
}
.innerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #d6e3f3;

  border-radius: 20px;
  width: 67vw;
  height: 70vh;
}
.header {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 2.5vw;
  line-height: 49px;
  margin: 0 10vw 6vh 0;
  color: #1d59af;
}
.atomicLoops {
  display: flex;

  align-items: center;
  margin-left: 4vw;
}
.poweredByText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 25px;
  /* identical to box height */

  color: #000000;
}
.atomicImg {
  width: 9.8vw;
  height: 5vh;
}
.returnText {
  width: 40vw;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.389vw;
  line-height: 138.5%;
  /* or 28px */

  text-align: center;

  color: #02080d;
}
.formLabel {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.11vw;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #8b9eb0;
}
.otpInput {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.11vw;
  line-height: 18px;

  width: 37.815vw;
  height: 6.4vh;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  color: #113a62;
  background: #e3edf7;
  border-radius: 4px;
  margin-top: 1vh;
  margin-bottom: 3vh;
  padding-left: 1vw;
  box-shadow: inset -4px -4px 9px rgba(255, 255, 255, 0.6),
    inset 4px 4px 14px #c5d7ee;
}
