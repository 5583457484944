/* upadte delete user css */

.section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 84vw;
  margin-top: 8vh;
  margin-left: 2vw;
}
.form {
  width: 40vw;

  align-items: center;
}
.inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40vw;
  height: 7vh;

  border-bottom: 1px solid #eaecf0;
}
.label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */

  color: #8b9eb0;
}
.form_input {
  width: 20vw;
  height: 6vh;
  background: #ffffff;
  border: 1px solid #8b9eb0;
  border-radius: 5px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */
  outline: none;
  color: #000000;
}
.form_inputs {
  display: flex;
  width: 20vw;
  height: 6vh;
  background: #ffffff;
  align-items: center;
  padding-left: 2rem;
}
.buttons_group {
  display: flex;

  width: 40vw;
  justify-content: space-between;
  margin-top: 2vh;
  gap: 2vw;
}
.button {
  background: #1d59af;
  border-radius: 5px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.2px;
  width: 16vw;
  height: 6vh;
  color: #ffffff;
  border: none;
  outline: none;
}
.delete_main {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.delete_p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */
  margin-top: 2rem;
  margin-bottom: 2rem;
  letter-spacing: -0.4px;

  color: #8b9eb0;
}
.delete_input {
  width: 19vw;
  height: 5vh;
  background: #ffffff;
  border: 1px solid #8b9eb0;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.38vw;
  line-height: 20px;
  /* or 100% */

  letter-spacing: -0.4px;

  color: #8b9eb0;
  border-radius: 5px;
  padding-left: 1vw;

  outline: none;
}
.delete_button {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 25px;
  /* identical to box height */
  width: 30vw;
  height: 6vh;
  letter-spacing: 0.2px;
  margin-top: 4vh;
  color: #ffffff;
  background: #d45858;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-bottom: 2vh;
}
