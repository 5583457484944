/* upadte delete user css */

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 84vw;
  margin-top: 8vh;
  margin-left: 2vw;
}
.form_section {
  display: flex;

  gap: 2vw;
  width: 80vw;
  align-items: center;
}
.image_section {
  width: 36vw;
  height: 59vh;
}
.form_data {
  align-items: center;
  width: 50vw;
}
.update_img {
  width: 32vw;
  height: 59vh;
  object-fit: cover;
}
.inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
  height: 7vh;

  border-bottom: 1px solid #eaecf0;
}
.label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */

  color: #8b9eb0;
}

.selectInput {
  width: 16vw;
  height: 6vh;
  background: #ffffff;
  border: 1px solid #8b9eb0;
  border-radius: 5px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */
  outline: none;
  color: #000000;
}
.updateInput {
  width: 19vw;
  height: 6vh;
  background: #ffffff;

  border-radius: 5px;

  outline: none;
}
.updatedelete_form_inputs {
  display: flex;
  align-items: center;

  height: 6vh;
  background: #ffffff;
}
.update_btnbox {
  display: flex;
  align-items: center;
  width: 30vw;
  justify-content: space-between;
  margin-top: 4vh;
}
.upadte_save_btn {
  background: #1d59af;
  border-radius: 5px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.2px;
  width: 14vw;
  height: 6vh;
  color: #ffffff;
  border: none;
  outline: none;
}
.delete_main {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.delete_p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */
  margin-top: 2rem;
  margin-bottom: 2rem;
  letter-spacing: -0.4px;

  color: #8b9eb0;
}
.delete_input {
  width: 19vw;
  height: 5vh;
  background: #ffffff;
  border: 1px solid #8b9eb0;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.38vw;
  line-height: 20px;
  /* or 100% */

  letter-spacing: -0.4px;

  color: #8b9eb0;
  border-radius: 5px;
  padding-left: 1vw;

  outline: none;
}
.delete_btn {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 25px;
  /* identical to box height */
  width: 30vw;
  height: 6vh;
  letter-spacing: 0.2px;
  margin-top: 4vh;
  color: #ffffff;
  background: #d45858;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-bottom: 2vh;
}
/* user searchbar css */

.search_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  width: 76vw;
  height: 6vh;
  margin-top: 5vh;
  margin-left: 20px;
}
.search_actions {
  width: 10vw;
  height: 6vh;
  background: #c1d5ee;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */

  text-align: center;
  letter-spacing: -0.4px;

  color: #366cbd;

  border-radius: 10px;
}
.search_option {
  width: 13vw;
  height: 4vh;
  border: 4px;
  background: #d6e3f3;
}
.search_search_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42vw;
  height: 6vh;
  background: #f5f5f5;
  border: 1px solid #8b9eb0;
  border-radius: 10px;
}
.search_input {
  width: 40vw;
  height: 6vh;
  border: none;
  margin-left: 1vw;
  outline: none;
  background-color: transparent;
}
.cancel_search {
  width: 1vw;
  height: 1.5vh;
  object-fit: contain;
  border: none;
  margin-right: 2rem;
}
.search_btn {
  width: 10vw;
  height: 5vh;

  background: #d6e3f3;
  border-radius: 4px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */

  text-align: center;
  letter-spacing: -0.4px;
  margin-right: 1vw;
  color: #1d59af;
  outline: none;
  border: none;
}
.addnew_btn {
  width: 10vw;

  height: 6vh;
  background: #1d59af;
  border-radius: 10px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */

  letter-spacing: 0.2px;

  color: #ffffff;
  outline: none;
  border: none;
}
/* pagination css */

.paginationmain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  width: 67vw;
  margin-left: 10px;
}
.pagination_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 11vw;
  justify-content: space-around;
  height: 3vh;
}
/* .pagination_left {
  height: 19px;
  width: 11px;
  margin: 0 2rem;
  border-radius: 0px;

  color: #8b9eb0;
} */
.pagination_number {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 0.972vw;
  line-height: 19px;

  color: #8b9eb0;
}
