.wrapper {
  display: flex;

  justify-content: space-between;
  width: 100vw;
  height: 100vh;
}
.leftSide {
  margin-top: 5vh;
  margin-left: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sulzerLogo {
  width: 20vw;
  height: 5vh;
  object-fit: contain;
}
.header {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  margin: 4vh 0 0 0;
  color: #1d59af;
}

.subHeader {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #000000;
  margin: 0;
}
.form {
}
.atomicLoops {
  display: flex;

  align-items: center;
}
.poweredByText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #000000;
}
.atomicImg {
  width: 9.8vw;
  height: 5vh;
}
.adminImage {
  height: 100vh;
  object-fit: cover;

  width: 50vw;
}
.links {
  text-decoration: none;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #1d59af;
}
