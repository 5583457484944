.container {
  display: flex;
  justify-content: space-between;

  width: 84vw;
  height: 88vh;
  margin-left: 2vw;
}
.searchBarAndTable {
  display: flex;
  flex-direction: column;
}
.searchBar {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;

  margin-right: 1vw;
  height: 12vh;
}

.cancelSearch {
  width: 2vw;
  height: 2vh;
  object-fit: contain;
  border: none;
  margin-right: 2vw;
}
.selectAction {
  width: 10vw;
  height: 8vh;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */

  letter-spacing: -0.4px;

  color: #366cbd;
  padding: 0 2vw;
}
.inputBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 42vw;
  background: #f5f5f5;
  border-radius: 4px;
}
.searchInput {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */

  letter-spacing: -0.4px;

  color: #8b9eb0;

  width: 30vw;
  height: 8vh;
  background-color: transparent;
  color: #8b9eb0;
  padding-left: 1vw;
  border: none;
  outline: none;
}
.searchBtn {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */

  text-align: center;
  letter-spacing: -0.4px;

  color: #1d59af;
  border: none;
  outline: none;
  border-radius: 4px;
  width: 8vw;
  height: 6vh;
  background: #d6e3f3;
  color: #1d59af;
  margin-right: 1vw;
}
/* pagination css */
.paginationBar {
  display: flex;
  justify-content: center;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  position: fixed;
  bottom: 0;
  gap: 3.175vw;
  padding-bottom: 2.2vh;
}

.prevBtn {
  height: 4vh;
  border: none;
  border-radius: 4px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.042vw;
  line-height: 100%;
  /* identical to box height, or 15px */

  text-align: right;
  letter-spacing: -0.4px;
  background: none;
  cursor: pointer;

  color: #1d59af;
}
.prevBtnColor {
  height: 4vh;
  border: none;
  border-radius: 4px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.042vw;
  line-height: 100%;
  /* identical to box height, or 15px */

  text-align: right;
  letter-spacing: -0.4px;
  background: none;
  cursor: pointer;

  color: brown;
}
/* table  css*/

.userClearBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userResult {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.11vw;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.4px;

  color: #8b9eb0;
}
.userResultBtn {
  height: 4vh;
  border: none;
  border-radius: 4px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.11vw;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: right;
  letter-spacing: -0.4px;

  color: #1d59af;
  background-color: transparent;
  margin-right: 2vw;
}
.tableBox {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  height: 64vh;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.tables {
  width: 64vw;
}
.tableHead {
  height: 6vh;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.tableHeadData {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;

  color: #8b9eb0;
}
.tableRow {
  height: 6vh;
  text-align: center;
  background: #ffffff;
}
.elseTableBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  height: 47vh;
}
.emptyImg {
  height: 7vh;
  width: 5vw;
  object-fit: contain;
  border-radius: 0px;
}
.emptyText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 20px;
  /* identical to box height, or 77% */

  letter-spacing: -0.4px;

  color: #8b9eb0;
}
.tableEditData {
  color: #366cbd;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 25px;
}
.tableRowData {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 25px;

  color: #000000;
}
/* Sidebar filter css*/
.sideFilter {
  background: rgba(214, 227, 243, 0.36);
  width: 18vw;
}
.filterMain {
  height: 78vh;
  margin-top: 2.5vh;

  background: rgba(214, 227, 243, 0.36);
}
.filterHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.5vw;
  margin-right: 2vw;
  margin-top: 2vh;
}
.filterLeftP {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.042vw;
  line-height: 100%;
  /* identical to box height, or 15px */

  text-align: right;
  letter-spacing: -0.4px;

  color: #1d59af;

  margin: 0px;
  border: 0px;
  background-color: transparent;
}
.filterRightP {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.042vw;
  line-height: 100%;
  /* identical to box height, or 15px */

  text-align: right;
  letter-spacing: -0.4px;

  color: #1d59af;

  border: 0px;
  margin: 0px;
  background-color: transparent;
}
.filtersText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;
  margin-left: 1vw;
  color: #8b9eb0;
}
.filterCreate {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;

  color: #000000;

  margin: 1vh 1vw;
}
.startDate {
  width: 12vw;
  height: 6vh;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.11vw;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.4px;

  color: #8b9eb0;
  border: 1px solid #8b9eb0;
  border-radius: 4px;
  margin-left: 1vw;
  outline: none;
}
.toText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 0.972vw;
  line-height: 19px;
  letter-spacing: 0.2px;

  color: #8b9eb0;
  margin-left: 1vw;
  margin-top: 0.5vh;
}
.filterRadioBlock {
  margin-left: 1vw;
}
.radioSqrBtn {
  box-sizing: border-box;

  background: transparent;
  outline: 2px solid #f5f5f5;
  border: 3px solid #8b9eb0;
  width: 14px;
  height: 14px;
}

.radioBtnText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 100%;
  /* identical to box height, or 18px */

  letter-spacing: -0.4px;

  color: #8b9eb0;
}
.radioBtn {
  width: 14px;
  height: 14px;
  box-sizing: border-box;

  background: transparent;
  border: 3px solid #8b9eb0;
  align-items: center;
}
.filterSeeUserLevel {
  font-family: "Manrope";
  font-style: normal;

  color: #1d59af;
}
.filterUserLevel {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 100%;
  /* identical to box height, or 18px */

  letter-spacing: -0.4px;
  margin: 2rem 0rem;
  color: #8b9eb0;
}
.levelWrapper {
  display: flex;
  gap: 1vh;
  flex-direction: column;
}
.radioSqrBtn2 {
  display: flex;
  /* margin-left: 1rem; */
  width: 100%;
  gap: 0.5vh;
  padding: 0px 1vw;
}
