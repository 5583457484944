/* .container {
  display: flex;
  flex-direction: column;

  width: 84vw;
  height: 12vh;
} */
.componentBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3vh 2vw;
}
.leftSide {
  display: flex;
  flex-direction: column;
}
.head {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7vw;
  line-height: 20px;
  /* identical to box height, or 83% */

  letter-spacing: -0.4px;

  color: #000000;
  margin: 0;
}

.paras {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.38vw;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: -0.4px;
  margin: 0;
  padding-top: 0.967vh;

  color: #000000;
}
#user {
  padding-top: 0;
}
.adminName {
  font-family: "Manrope";
  font-style: normal;

  color: #000000;
}
.rightSide {
  display: flex;
  align-items: center;
  gap: 1vw;
}
.profileBlock {
  display: flex;
}
.adminProfile {
  width: 4vw;
  height: 6vh;
}
