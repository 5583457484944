.generalButton {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 20px;
  /* identical to box height, or 95% */

  text-align: center;
  letter-spacing: -0.4px;
  cursor: pointer;
  color: #fff;
  padding: 18px 22px;
  border: none;
  background-color: #366cbd;
  box-shadow: 2px 2px 4px rgba(114, 142, 171, 0.1), -6px -6px 20px #ffffff,
    4px 4px 20px rgba(111, 140, 176, 0.41);
  border-radius: 10px;
}
