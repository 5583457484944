.container {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
}
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16vw;
  height: 100vh;
  position: fixed;
  margin: 0;
  background-color: #c1d5ee;
}

.sulzerlogo {
  width: 11vw;
  height: 4vh;
  object-fit: contain;
  margin-left: 2vw;
  margin-top: 4.3vh;
}
.sidebar_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.atomicLoops {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 0.972vw;
  line-height: 19px;
  margin-left: 2vw;
  color: #000000;
}
.sidebar_items {
  display: flex;
  align-items: center;

  height: 6vh;
  background-color: #c1d5ee;
  cursor: pointer;
  transition: all 0.1s ease-in;
  text-decoration: none;
}
.sidebar_items:hover {
  background-color: #d6e3f3;
  border-left: 10px solid #1d59af;
  outline-offset: 10px;
}
.sidebar_data {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;

  margin-left: 2vw;

  color: #000000;
}
.active {
  display: flex;
  align-items: center;

  height: 6vh;
  background: #d6e3f3;
  border-left: 5px solid #1d59af;
  cursor: pointer;
  transition: all 0.1s ease-in;
  text-decoration: none;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;
  outline-offset: 10px;
}

.content {
  width: 84vw;
  margin-left: 16vw;

  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}
.sidebar_bottom_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logoutBtn {
  width: 12vw;
  height: 6vh;
  border: 2px solid #1d59af;
  border-radius: 10px;
  outline: none;
  background-color: transparent;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 20px;
  /* identical to box height, or 111% */

  letter-spacing: 0.2px;

  color: #1d59af;

  margin-left: 2vw;
}
